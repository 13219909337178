import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    private readonly TOKEN_NAME = 'flex_auth';

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let data = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
        if (data != null) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${data}`
            }
          });
        }
    
        return next.handle(request).pipe(tap(() => {},
        (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
           return;
          }
          this.router.navigate(['login']);
        }
      }));
    }
}
